/* body {
    font-size: 0.75rem;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #000000;
    margin: 0 auto;
    position: relative;
  } */
  
  #pspdfkit-header {
    font-size: 0.625rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    color: #717885;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%;
  }
  
  .header-columns {
    display: flex;
    justify-content: space-between;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  
  .logo {
    height: 1.5rem;
    width: auto;
    margin-right: 1rem;
  }
  
  .logotype {
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  
  /* h2 {
    font-family: "Space Mono", monospace;
    font-size: 1.25rem;
    font-weight: 400;
  }
  
  h4 {
    font-family: "Space Mono", monospace;
    font-size: 1rem;
    font-weight: 400;
  } */
  
  .page {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  
  .intro-table {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0 3rem 0;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  
  .intro-form {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #000000;
    width: 50%;
  }
  
  .intro-form:last-child {
    border-right: none;
  }
  
  .intro-table-title {
    font-size: 0.625rem;
    margin: 0;
  }
  
  .intro-form-item {
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
  }
  
  .intro-form-item:first-child {
    padding-left: 0;
  }
  
  .intro-form-item:last-child {
    padding-right: 0;
  }
  
  .intro-form-item-border {
    padding: 1.25rem 0 0.75rem 1.5rem;
    border-bottom: 1px solid #000000;
  }
  
  .intro-form-item-border:last-child {
    border-bottom: none;
  }
  
  /* .form {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
  } */
  
  .no-border {
    border: none;
  }
  
  .border {
    border: 1px solid #000000;
  }
  
  .border-bottom {
    border: 1px solid #000000;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  
  .signer {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
    margin: 2rem 0 2rem 0;
  }
  
  .signer-item {
    flex-grow: 1;
  }
  
  /* input {
    color: #4537de;
    font-family: "Space Mono", monospace;
    text-align: center;
    margin-top: 1.5rem;
    height: 4rem;
    width: 100%;
    box-sizing: border-box;
  } */
  
  input#date,
  input#notes {
    text-align: left;
  }
  
  input#signature {
    height: 8rem;
  }
  
  .intro-text {
    width: 60%;
  }
  
  .table-box table,
  .summary-box table {
    width: 100%;
    font-size: 0.625rem;
  }
  
  .table-box table {
    padding-top: 2rem;
  }
  
  .table-box td:first-child,
  .summary-box td:first-child {
    width: 50%;
  }
  
  .table-box td:last-child,
  .summary-box td:last-child {
    text-align: right;
  }
  
  .table-box table tr.heading td {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    height: 1.5rem;
  }
  
  .table-box table tr.item td,
  .summary-box table tr.item td {
    border-bottom: 1px solid #d7dce4;
    height: 1.5rem;
  }
  
  .summary-box table tr.no-border-item td {
    border-bottom: none;
    height: 1.5rem;
  }
  
  .summary-box table tr.total td {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    height: 1.5rem;
  }
  
  .summary-box table tr.item td:first-child,
  .summary-box table tr.total td:first-child {
    border: none;
    height: 1.5rem;
  }
  
  .button {
    background: #4537de;
    border-color: #4537de;
    box-shadow: 0 0.1rem 0.3rem #4537de66;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-variation-settings: "wght" 550;
    font-weight: 400;
    padding: 0.75rem 1.5rem;
    text-align: center;
    margin: 10px auto;
  }
  